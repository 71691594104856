import React, { useState } from "react";
import { PageWrapper } from "./PageWrapper";
import TrophyIcon from "./TrophyIcon";
import {
  Typography,
  Chip,
  Box,
  IconButton,
  CardContent,
  Card,
  Grid,
  Collapse,
  Fab,
} from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { red, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import useLongPress from "./util/useLongPress";
import { useAuthContext } from "./Components/Provider/AuthProvider";
import deLocale from "date-fns/locale/de";
import { format } from "date-fns";
import * as Constants from "./util/constants";
import { useStorageContext } from "./Components/Provider/StorageProvider";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import max from "lodash/max";
import amber from '@material-ui/core/colors/amber';
import Category from "./Enums/Category"
import {ReactComponent as EditIcon} from './icons/edit.svg'

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginAuto: {
    margin: "auto",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  dateCard: {
    borderRadius: 100,
    boxShadow: theme.shadows[3],
  },
  box: {
    justifyContent: "center",
  },
  editIconBg: {
    backgroundColor: `${grey[400]}`,
  },
  editIcon: {
    scale: 0.7,
    fill: "white",
  },
  trophyIcon: {
    zIndex: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    fontSize: "5rem",
    opacity: 0.4,
  },
  playerText: {
    zIndex: 1,
    position: "relative",
  },
  teamItem: {
    position: "relative",
  },
  cardContent: {
    "&:last-child": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  iconButton: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  collapseContainer: {
    padding: theme.spacing(1),
  },
}));

const formatDate = (date) =>
  format(new Date(date), Constants.DEDATEFORMAT, {
    locale: deLocale,
  });


export const History = (props) => {
  const { storage } = useStorageContext();

  const getMatchesByCategory = () => {  
    if (storage.selectedCategory === Category.male)
      return storage.matches.filter(x => x.category === Category.male);
    if (storage.selectedCategory === Category.female)
      return storage.matches.filter(x => x.category === Category.female);
    if (storage.selectedCategory === Category.mixed)
      return storage.matches.filter(x => x.category === Category.mixed);
    return [];
  } 

  const matchesGroupedByDate = groupBy(getMatchesByCategory(), (match) =>
    formatDate(match.date)
  );
  const latestDate = storage.matches.length ? formatDate(max(storage.matches, (m) => m.date).date) : ''

  return (
    <PageWrapper>
      <Grid container direction="column" spacing={2}>
        {map(matchesGroupedByDate, (matches, key) => (
          <MatchGroup
            {...props}
            matches={matches}
            date={key}
            openInit={key === latestDate}
            key={key}
          />
        ))}
      </Grid>
    </PageWrapper>
  );
};

const MatchGroup = (props) => {
  const { matches, date, openInit } = props;
  const [open, setOpen] = useState(openInit);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <Grid item xs={12}>
        <Card className={classes.dateCard} onClick={() => handleClick()}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box flex={1}>
              <IconButton
                className={classes.iconButton}
                size="small"
                onClick={() => handleClick()}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Box>
            <Typography align="center">{date}</Typography>
            <Box flex={1} />
          </Box>
        </Card>
      </Grid>
      <Collapse
        in={open}
        mountOnEnter
        unmountOnExit
        component="div"
        className={classes.collapseContainer}
      >
        <Grid container spacing={2}>
          {matches.map((m) => (
            <HistoryItem {...props} match={m} key={m.rowKey} />
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

const PlayerText = (props) => {
  const classes = useStyles();
  const { playerName, ratingPrev, ratingNew } = props;
  return (
    <Typography
      component="div"
      variant="body2"
      noWrap
      align="center"
      className={classes.playerText}
    >
      <Box fontWeight="fontWeightMedium">{playerName}</Box>
      <Box>
        [{ratingPrev.toFixed(0)} ➟ {ratingNew.toFixed(0)}]
      </Box>
    </Typography>
  );
};

const HistoryItem = (props) => {
  const { authToken } = useAuthContext();
  const { storage, storageDispatch } = useStorageContext();
  const classes = useStyles();
  const { match } = props;
  const getPlayerNameById = (id) => {
    return storage.players.find((p) => p.rowKey === id)?.name;
  }

  // const longPressProps = useLongPress(() => {
  //   if (authToken == null) return;
  //   // storage({ type: "selectedMatchChange", selectedMatch: match });
  //   props.history.push("/addmatch");
  // }, Constants.LONGPRESSTIMEOUT);

  const handleEdit = (match) => {
    storageDispatch({
      type: "selectedMatchChange",
      selectedMatch: match,
    });
    props.history.push("/addmatch");
  };
  const formattedTime = format(new Date(match.date), Constants.DETIMEFORMAT, {
    locale: deLocale,
  });

  const trophyIcon = (
    <TrophyIcon className={classes.trophyIcon} htmlColor={amber[500]} />
  );

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Box justifyContent="center" display="flex">
                <Chip label={formattedTime} size="small" />
              </Box>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={0}>
                <Grid item xs={5} className={classes.teamItem}>
                  <PlayerText
                    playerName={getPlayerNameById(match.playerA1Id)}
                    ratingPrev={match.playerA1Rating}
                    ratingNew={match.teamAWins? match.playerA1Rating + match.playerA1RatingDelta: match.playerA1Rating - match.playerA1RatingDelta}
                  />
                  <PlayerText
                    playerName={getPlayerNameById(match.playerA2Id)}
                    ratingPrev={match.playerA2Rating}
                    ratingNew={match.teamAWins? match.playerA2Rating + match.playerA2RatingDelta: match.playerA2Rating - match.playerA2RatingDelta}
                  />
                  {match.teamAWins && trophyIcon}
                </Grid>
                <Grid item xs={2} className={classes.flexCenter}>
                      {authToken != "edit"? 
                      <Typography variant="h5" className={classes.marginAuto}>
                        vs.                               
                      </Typography>
                      : <Fab
                      className={classes.editIconBg}
                      size="small"
                      onClick={() => handleEdit(match)}
                    >
                      <EditIcon className={classes.editIcon} />
                    </Fab>
                      }
                </Grid>
                <Grid item xs={5} className={classes.teamItem}>
                <PlayerText
                    playerName={getPlayerNameById(match.playerB1Id)}
                    ratingPrev={match.playerB1Rating}
                    ratingNew={match.teamAWins? match.playerB1Rating - match.playerB1RatingDelta: match.playerB1Rating + match.playerB1RatingDelta}
                  />
                  <PlayerText
                    playerName={getPlayerNameById(match.playerB2Id)}
                    ratingPrev={match.playerB2Rating}
                    ratingNew={match.teamAWins? match.playerB2Rating - match.playerB2RatingDelta: match.playerB2Rating + match.playerB2RatingDelta}
                  />
                  {!match.teamAWins && trophyIcon}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default History;
