import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, makeStyles, Fab } from "@material-ui/core";
import { useStorageContext } from "./Components/Provider/StorageProvider";
import { lightBlue, pink, red } from "@material-ui/core/colors";
import Category from "./Enums/Category";
import { ReactComponent as MaleIcon } from "./icons/male.svg";
import { ReactComponent as FemaleIcon } from "./icons/female.svg";
import { ReactComponent as MixedIcon } from "./icons/mixed.svg";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
  },
  trophy: {
    margin: "auto",
    fontSize: "6rem",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  femaleIcon: {
    backgroundColor: `${pink[400]}`,
  },
  mixedIcon: {
    backgroundColor: `${red[500]}`,
  },
  maleIcon: {
    backgroundColor: `${lightBlue[400]}`,
  },
  chip: {
    border: `2.5px solid ${theme.palette.background.paper}`,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));
export const SelectCategory = (props) => {
  const classes = useStyles();
  const { storageDispatch } = useStorageContext();
  const history = useHistory();
  const handleSelectCategory = (category) => {
    storageDispatch({
      type: "selectedCategoryChange",
      selectedCategory: category,
    });
    history.push("/addmatch");
  };

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid container xs={2} justify="center">
        <Fab
          className={classes.maleIcon}
          size="large"
          onClick={() => handleSelectCategory(Category.male)}
        >
          <MaleIcon />
        </Fab>
      </Grid>
      <Grid container xs={2} justify="center">
        <Fab
          className={classes.mixedIcon}
          size="large"
          onClick={() => handleSelectCategory(Category.mixed)}
        >
          <MixedIcon />
        </Fab>
      </Grid>
      <Grid container xs={2} justify="center">
        <Fab
          className={classes.femaleIcon}
          size="large"
          onClick={() => handleSelectCategory(Category.female)}
        >
          <FemaleIcon />
        </Fab>
      </Grid>
    </Grid>
  );
};
