import React from "react";
import {
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  createStyles,
  useScrollTrigger,
  Slide,
} from "@material-ui/core";
import { SelectCategory } from "./SelectCategory";
import { AddMatch } from "./AddMatch";
import { Standings } from "./Standings";
import History from "./History";
import Settings from "./Components/Settings/Settings";
import {
  History as HistoryIcon,
  FormatListNumbered,
  Add,
} from "@material-ui/icons";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { useStorageContext } from "./Components/Provider/StorageProvider";

const useStyles = makeStyles((theme) =>
  createStyles({
    app: {
      userSelect: "none",
    },
    offset: theme.mixins.toolbar,
  })
);

const App = (props) => {
  const { storage } = useStorageContext();
  const classes = useStyles();
  const routes = ["/selectcategory", "/addmatch", "/standings", "/history"];
  return (
    <div className={classes.app}>
      <Route
        path="/"
        render={({ location }) => (
          <>
            <HideOnScroll {...props}>
              <AppBar position="fixed" color="default">
                <Tabs
                  value={location.pathname}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  disabled={storage.selectedCategory === -1}
                >
                  <Tab
                    label="Eintragen"
                    icon={<Add />}
                    value={routes[1]}
                    component={Link}
                    to={routes[1]}
                    disabled={storage.selectedCategory === -1}
                  />
                  <Tab
                    label="Tabelle"
                    icon={<FormatListNumbered />}
                    value={routes[2]}
                    component={Link}
                    to={routes[2]}
                    disabled={storage.selectedCategory === -1}
                  />
                  <Tab
                    label="Spiele"
                    icon={<HistoryIcon />}
                    value={routes[3]}
                    component={Link}
                    to={routes[3]}
                    disabled={storage.selectedCategory === -1}
                  />
                </Tabs>
              </AppBar>
            </HideOnScroll>
            <div className={classes.offset} />
            <Settings />
            <Switch>
              <Route
                path={routes[0]}
                render={(props) => <SelectCategory {...props} />}
              />
              <Route
                path={routes[1]}
                render={(props) => <AddMatch {...props} />}
              />
              <Route
                path={routes[2]}
                render={(props) => <Standings {...props} />}
              />
              <Route
                path={routes[3]}
                render={(props) => <History {...props} />}
              />
              <Redirect from="/" to={routes[0]} />
            </Switch>
          </>
        )}
      />
    </div>
  );
};

export default App;

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};
