import React from "react";
import { makeStyles, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));
export const PageWrapper = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.container}>
      {props.children}
    </Container>
  );
};
