import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "typeface-roboto";
import ThemeProvider from "./Components/Provider/ThemeProvider";
import AuthProvider from "./Components/Provider/AuthProvider";
import StorageProvider from "./Components/Provider/StorageProvider";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de";
import DateFnsUtils from "@date-io/date-fns";

import { BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(
  <ThemeProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <AuthProvider>
        <StorageProvider>
          <Router>
            <App />
          </Router>
        </StorageProvider>
      </AuthProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
