import { createContext, useContext, useEffect } from "react";
import React from "react";
import API from "../../util/api";
import { useStorageReducer } from "../../reducer/storageReducer";
import { Loading } from "../Loading";

const Context = createContext(null);

export const useStorageContext = () => useContext(Context);

export const loadData = (storageDispatch) => {
  const playerPromise = API.get("/players").then((res) => {
    storageDispatch({ type: "playersChange", players: res.data });
  });
  const matchPromise = API.get("/matches").then((res) => {
    storageDispatch({ type: "matchesChange", matches: res.data });
  });
  Promise.all([playerPromise, matchPromise]).then(() => {
    storageDispatch({ type: "loadingChange", loading: false });
  });
};

const StorageProvider = ({ children }) => {
  const [storage, storageDispatch] = useStorageReducer();

  useEffect(() => {
    loadData(storageDispatch);
  }, [storageDispatch]);

  if (storage.loading) return <Loading />;
  return (
    <Context.Provider value={{ storage, storageDispatch }}>
      {children}
    </Context.Provider>
  );
};

export default StorageProvider;
