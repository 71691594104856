import React, { useState, useEffect } from "react";
import API from "./util/api";
import {
  makeStyles,
  Button,
  MenuItem,
  Grid,
  CircularProgress,
  Fab,
  Card,
} from "@material-ui/core";
import { PageWrapper } from "./PageWrapper";
import { green, red } from "@material-ui/core/colors";
import {
  Check,
  SportsVolleyball,
  Delete,
  Done,
  Sync,
  ClearAll,
} from "@material-ui/icons";
import TeamSelection from "./TeamSelection";
import { useStorageContext } from "./Components/Provider/StorageProvider";
import { DateTimePicker } from "@material-ui/pickers";
import * as Constants from "./util/constants";
import formatISO from "date-fns/formatISO";
import { loadData } from "./Components/Provider/StorageProvider";
import { getPointsForWins } from "./util/eloCalulations";
import Category from "./Enums/Category"

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  paper: {
    padding: "24px",
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  winner: {
    backgroundColor: "#aed581",
  },
  looser: {
    backgroundColor: "#e57373",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  dtPicker: {
    padding: theme.spacing(2),
  },
  roundButton: {
    borderRadius: 100,
  },
}));

export const AddMatch = (props) => {
  const classes = useStyles();
  const { storage, storageDispatch } = useStorageContext();
  const [success, setSuccess] = useState(false);
  const [addingMatch, setAddingMatch] = useState(false);
  const selectedMatch = storage.selectedMatch;

  const handleAddMatch = () => {
    setAddingMatch(true);
    selectedMatch.category = storage.selectedCategory;
    selectedMatch.TeamAWins = winner;
    API.post("matches", selectedMatch).then((res) => {
      setSuccess(true);
      setAddingMatch(false);
      loadData(storageDispatch);
      setTimeout(() => setSuccess(false), 5000);
    });
  };

  const handleDiscardMatch = () => {
    storageDispatch({ type: "resetSelectedMatch" });
  };

  const handleUpdateMatch = () => {
    API.put("matches", selectedMatch).then((res) => {
      loadData(storageDispatch);
      storageDispatch({ type: "resetSelectedMatch" });
    });
  };

  const handleDeleteMatch = () => {
    API.delete(`${"/matches"}/${selectedMatch.rowKey}`).then((res) => {
      loadData(storageDispatch);
      storageDispatch({ type: "resetSelectedMatch" });
    });
  };
  const getPlayersByCategory = () => {
    
    if (storage.selectedCategory === Category.male)
      return storage.players.filter(x => x.category === Category.male).sort();
    if (storage.selectedCategory === Category.female)
      return storage.players.filter(x => x.category === Category.female).sort();
    if (storage.selectedCategory === Category.mixed)
      return storage.players.filter(x => x.category === Category.mixed).sort();
    return [];
  } 
  const compareForName = ( a, b ) => {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  const {
    playerA1Id,
    playerA2Id,
    playerB1Id,
    playerB2Id,
    winner,
    rowKey,
    date,
  } = selectedMatch;

  useEffect(() => {});

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  const isValidMatch = () => {
    const selectedPlayers = [playerA1Id, playerA2Id, playerB1Id, playerB2Id];
    if (selectedPlayers.some((p) => p === undefined)) return false;

    return !hasDuplicates(selectedPlayers);
  };
  const players = getPlayersByCategory();
  const menuItems = players.sort(compareForName).map((p) => {
    return (
      <MenuItem value={p.rowKey} key={p.rowKey}>
        {p.name}
      </MenuItem>
    );
  });

  const sharedButtonProps = {
    className: classes.roundButton,
    fullWidth: true,
    variant: "contained",
    // size: "small",
  };

  const buttonGreen = {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  };

  const buttonRed = {
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  };

  const getPlayerById = (rowKey) => {
    return storage.players.find((p) => p.rowKey === rowKey);
  };

  const playerA1 = getPlayerById(storage.selectedMatch.playerA1Id);
  const playerA2 = getPlayerById(storage.selectedMatch.playerA2Id);
  const playerB1 = getPlayerById(storage.selectedMatch.playerB1Id);
  const playerB2 = getPlayerById(storage.selectedMatch.playerB2Id);

  let ratingDelta;
  let ratingDeltaA;
  let ratingDeltaB;

  if (playerA1 && playerA2 && playerB1 && playerB2) {
    let { winA, winB } = getPointsForWins(
      playerA1,
      playerA2,
      playerB1,
      playerB2
    );
    ratingDelta = winner ? winA : winB;
    ratingDeltaA = [ratingDelta[0], ratingDelta[1]];
    ratingDeltaB = [ratingDelta[2], ratingDelta[3]];
  }

  const isEditing = rowKey != null;
  return (
    <PageWrapper>
      <Grid container direction="column" spacing={3}>
        <TeamSelection
          team={"A"}
          menuItems={menuItems}
          winner={winner}
          ratingDelta={ratingDeltaA}
        />
        <Grid item>
          <Button
            onClick={() =>
              storageDispatch({
                type: "selectedMatchChange",
                selectedMatch: { ...selectedMatch, winner: !winner },
              })
            }
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<Sync />}
          >
            Sieger tauschen
          </Button>
        </Grid>
        <TeamSelection
          team={"B"}
          menuItems={menuItems}
          winner={!winner}
          ratingDelta={ratingDeltaB}
        />
        {isEditing && (
          <Grid item xs={12}>
            <Card className={classes.dtPicker}>
              <DateTimePicker
                label="gespielt am"
                format={Constants.DEDATETIMEFORMAT}
                fullWidth
                inputVariant="outlined"
                autoOk
                ampm={false}
                disableFuture
                value={date}
                onChange={(date) =>
                  storageDispatch({
                    type: "selectedMatchChange",
                    selectedMatch: { ...selectedMatch, date: formatISO(date) },
                  })
                }
              />
            </Card>
          </Grid>
        )}
        {isEditing ? (
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={3}
              alignItems="center"
              justify="center"
            >
              {/* <Grid item xs={12} md={4}>
                <Button
                  {...sharedButtonProps}
                  style={buttonGreen}
                  onClick={() => handleUpdateMatch()}
                  startIcon={<Done />}
                >
                  Aktualisieren
                </Button>
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Button
                  {...sharedButtonProps}
                  onClick={() => handleDiscardMatch()}
                  startIcon={<ClearAll />}
                >
                  Verwerfen
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  {...sharedButtonProps}
                  style={buttonRed}
                  onClick={() => handleDeleteMatch()}
                  startIcon={<Delete />}
                >
                  Löschen
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item className={classes.button}>
            <div className={classes.wrapper}>
              <Fab
                style={success ? buttonGreen : undefined}
                disabled={!isValidMatch()}
                aria-label="save"
                color="primary"
                onClick={() => handleAddMatch()}
              >
                {success ? <Check /> : <SportsVolleyball />}
              </Fab>
              {addingMatch && (
                <CircularProgress size={68} className={classes.fabProgress} />
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </PageWrapper>
  );
};