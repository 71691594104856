import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "loadingChange": {
      return { ...state, loading: action.loading };
    }
    case "playersChange": {
      return { ...state, players: action.players };
    }
    case "teamsChange": {
      return { ...state, teams: action.teams };
    }
    case "matchesChange": {
      return { ...state, matches: action.matches };
    }
    case "selectedMatchChange": {
      return { ...state, selectedMatch: action.selectedMatch };
    }
    case "resetSelectedMatch": {
      return { ...state, selectedMatch: emptyMatch };
    }
    case "selectedCategoryChange": {
      window.localStorage.setItem("category", action.selectedCategory);
      return { ...state, selectedCategory: action.selectedCategory };
    }
    default:
      console.log("not implemented action");
  }
};
const localCategory = window.localStorage.getItem("category")

const emptyMatch = {
  playerA1Id: "",
  playerA2Id: "",
  playerB1Id: "",
  playerB2Id: "",
  TeamAWins: true,
  winner: true,
  category: localCategory
};

const initialState = {
  loading: true,
  players: [],
  teams: [],
  matches: [],
  selectedMatch: emptyMatch,
  selectedCategory:  localCategory ? parseInt(localCategory) : -1,
};

export const useStorageReducer = () => useReducer(reducer, initialState);
