import React, { useEffect, useState } from "react";
import {
  withStyles,
  Grid,
  TableCell} from "@material-ui/core";

import { PageWrapper } from "./PageWrapper";
import { SoloStandings } from "./SoloStandings";
import { useStorageContext } from "./Components/Provider/StorageProvider";
import Category from "./Enums/Category"
export const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export const RankTableCell = withStyles((theme) => ({
  root: {
    textAlign: "center",
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export const Standings = () => {
  const { storage } = useStorageContext();

  const matches = storage.matches;

  const getPlayersOrderedByRating = (players) => {
    let playersCopy = [...players];
    return playersCopy
      .filter((p) => p.matchesPlayed !== 0)
      .sort((a, b) => {
        return b.rating - a.rating;
      });
  };  

  

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  
  useEffect(() => {
    const getPlayersByCategory = () => {
    
      if (storage.selectedCategory === Category.male)
        return storage.players.filter(x => x.category === Category.male);
      if (storage.selectedCategory === Category.female)
        return storage.players.filter(x => x.category === Category.female);
      if (storage.selectedCategory === Category.mixed)
        return storage.players.filter(x => x.category === Category.mixed);
      return [];
    } 
    
    setSelectedPlayers(getPlayersOrderedByRating(getPlayersByCategory()));
  }, [storage.players, storage.selectedCategory]);

  return (
    <PageWrapper>
      <Grid container direction="column" spacing={3}>
        <SoloStandings 
        matches={matches}
        players={selectedPlayers} />       
      </Grid>
    </PageWrapper>
    );
};
