import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from "@material-ui/core";
import { deDE } from "@material-ui/core/locale";

const sharedTheme = createMuiTheme({
  overrides: {},
});
const lightTheme = createMuiTheme(
  {
    ...sharedTheme,
    palette: {
      type: "light",
    },
  },
  deDE
);

const darkTheme = createMuiTheme(
  {
    ...sharedTheme,
    palette: {
      type: "dark",
    },
  },
  deDE
);

const Context = createContext(null);

export const useThemeContext = () => useContext(Context);

const ThemeProvider = ({ children }) => {
  const [theme, setThemeState] = useState("dark");

  const setTheme = (theme) => {
    localStorage.setItem("theme", JSON.stringify(theme));
    setThemeState(theme);
  };

  useEffect(() => {
    const theme = JSON.parse(localStorage.getItem("theme"));
    theme === null ? setTheme("light") : setThemeState(theme);
  }, []);

  return (
    <Context.Provider value={{ theme, setTheme }}>
      <MuiThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </Context.Provider>
  );
};

export default ThemeProvider;
