const fullAbsorptionLimit = 50;

export const getExpectedScoreTeamA = (
  ratingA1,
  ratingA2,
  ratingB1,
  ratingB2
) => {
  const avgRatingA = (ratingA1 + ratingA2) / 2.0;
  const avgRatingB = (ratingB1 + ratingB2) / 2.0;
  const pow = (avgRatingB - avgRatingA) / 500.0;
  return 1.0 / (1.0 + Math.pow(10, pow));
};

export const getPointsForWinsTeams = (playerA1, playerA2, playerB1, playerB2) => {
  const ea = getExpectedScoreTeamA(
    playerA1.rating,
    playerA2.rating,
    playerB1.rating,
    playerB2.rating
  );
  //Returns the delta for wins
  const winA = 100.0 * (1.0 - ea);
  const winB = 100.0 * ea;
  return { winA, winB };
};

export const getTanhFactor = (games) => {
  let tanhValue = Math.tanh(games / fullAbsorptionLimit);
  return 1 - 0.7 * tanhValue;
};

export const getPointsForWins = (playerA1, playerA2, playerB1, playerB2) => {
  const { winA, winB } = getPointsForWinsTeams(
    playerA1,
    playerA2,
    playerB1,
    playerB2
  );
  return {
    winA: [
      winA * getTanhFactor(playerA1.matchesPlayed),
      winA * getTanhFactor(playerA2.matchesPlayed),
      winA * getTanhFactor(playerB1.matchesPlayed),
      winA * getTanhFactor(playerB2.matchesPlayed),
    ],
    winB: [
      winB * getTanhFactor(playerA1.matchesPlayed),
      winB * getTanhFactor(playerA2.matchesPlayed),
      winB * getTanhFactor(playerB1.matchesPlayed),
      winB * getTanhFactor(playerB2.matchesPlayed),
    ],
  };
};