import axios from "axios";
import * as Constants from "./constants";

const instance = axios.create({
  baseURL: Constants.BASE_URL,
  responseType: "json",
});
instance.interceptors.request.use((config) => {
  console.log("getting token");
  const token = JSON.parse(localStorage.getItem("authToken"));
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

export default instance;
