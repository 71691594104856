import React, { useState } from "react";
import API from "../../util/api";
import {
  Chip,
  Link,
  Fade,
  Switch,
  Box,
  Drawer,
  Grid,
  TextField,
  Button,
  Fab,
  makeStyles,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useAuthContext } from "../Provider/AuthProvider";
import { useThemeContext } from "../Provider/ThemeProvider";
import { useStorageContext } from "../../Components/Provider/StorageProvider";
import {
  Mail,
  Close,
  Settings as SettingsIcon,
  Brightness4 as DarkIcon,
  BrightnessHigh as LightIcon,
} from "@material-ui/icons";
import {ReactComponent as MaleIcon} from '../../icons/male.svg'
import {ReactComponent as FemaleIcon} from '../../icons/female.svg'
import {ReactComponent as MixedIcon} from '../../icons/mixed.svg'
import Category from "../../Enums/Category";
import { lightBlue, pink, red } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: `calc(env(safe-area-inset-bottom) + ${theme.spacing(3)}px)`,
    zIndex: theme.zIndex.appBar + 1,
    right: theme.spacing(3),
  },
  paper: {
    width: 320,
    [theme.breakpoints.up("sm")]: {
      width: 425,
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    userSelect: "none",
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: "calc(env(safe-area-inset-top) + 16px)",
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  action: {
    padding: theme.spacing(1),
    paddingBottom: "calc(env(safe-area-inset-bottom) + 16px)",
  },
  femaleIcon: {
    backgroundColor: `${pink[400]}`,
  },
  maleIcon: {
    backgroundColor: `${lightBlue[400]}`,
  },
  mixedIcon: {
    backgroundColor: `${red[500]}`,
  },
}));

export const Settings = () => {
  const { storage, storageDispatch } = useStorageContext();
  const [open, setOpen] = useState(false);
  const [playerField, setPlayerField ] = useState("");
  const [categoryField, setCategoryField ] = useState("");

  const [showDevSettings, setShowDevSettings] = useState(false);
  const { authToken, setAuthToken } = useAuthContext();
  const { theme, setTheme } = useThemeContext();
  const [tokenField, setTokenField] = useState(authToken);

  const handleSelectCategory = (category) => {
    storageDispatch({
      type: "selectedCategoryChange",
      selectedCategory: category,
    });
  };

  const handleAddPlayer = () => {
    const newPlayer = { name: playerField, category: categoryField };
    setPlayerField("");
    API.post("/players", newPlayer).then(() => {});
  };

  const classes = useStyles();
  return (
    <>
      <Drawer
        open={open}
        PaperProps={{ className: classes.paper }}
        anchor="right"
        onClose={() => setOpen(false)}
      >
        <div className={classes.header}></div>
        <div className={classes.container}>
          <Grid container spacing={2} justify="left">
            <Grid item xs={12}>
              <Typography variant="h6">Einstellungen</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  {storage.selectedCategory === Category.male && (
                    <Fab
                      className={classes.maleIcon}
                      size="small"
                      onClick={() => {
                        handleSelectCategory(Category.mixed);
                      }}
                    >
                      {<MaleIcon />}
                    </Fab>
                  )}
                  {storage.selectedCategory === Category.mixed && (
                    <Fab
                      className={classes.mixedIcon}
                      size="small"
                      onClick={() => {
                        handleSelectCategory(Category.female);
                      }}
                    >
                      {<MixedIcon />}
                    </Fab>
                  )}
                  {storage.selectedCategory === Category.female && (
                    <Fab
                      className={classes.femaleIcon}
                      size="small"
                      onClick={() => {
                        handleSelectCategory(Category.male);
                      }}
                    >
                      {<FemaleIcon />}
                    </Fab>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body1">Kategorie</Typography>
                  <Typography variant="body2">
                    {storage.selectedCategory === Category.male && "Herren"}
                    {storage.selectedCategory === Category.mixed && "Mixed"}
                    {storage.selectedCategory === Category.female && "Damen"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  <Fab
                    size="small"
                    onClick={() => {
                      setTheme(theme === "dark" ? "light" : "dark");
                    }}
                  >
                    {theme === "dark" ? <LightIcon /> : <DarkIcon />}
                  </Fab>
                </Grid>
                <Grid item>
                  <Typography variant="body1">Theme</Typography>
                  <Typography variant="body2">hell oder dunkel</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs={2}>
                  <Switch
                    edge="start"
                    checked={showDevSettings}
                    onChange={() => setShowDevSettings(!showDevSettings)}
                    name="showDevSettings"
                    color="primary"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">Entwickleroptionen</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Fade in={showDevSettings} mountOnEnter unmountOnExit>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Authentifizierungstoken
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={tokenField}
                      onChange={(ev) => {
                        setTokenField(ev.target.value);
                      }}
                      fullWidth
                      label="Auth Token"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => setAuthToken(tokenField)}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Speichern
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">Spieler hinzufügen</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={playerField}
                      onChange={(ev) => {
                        setPlayerField(ev.target.value);
                      }}
                      fullWidth
                      label="Name des Spielers"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="category-label">Kategorie</InputLabel>
                    <Select
                    labelId="category-label"
                    id="category-select"
                    value={categoryField}
                    label="Kategorie"
                    onChange={(ev) => {
                      setCategoryField(ev.target.value);
                    }}
                    >
                      <MenuItem value={Category.male}>Männlich</MenuItem>
                      <MenuItem value={Category.mixed}>Mixed</MenuItem>
                      <MenuItem value={Category.female}>Weiblich</MenuItem>
                    </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={!playerField.length || categoryField === ""}
                      onClick={() => handleAddPlayer()}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Hinzufügen
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Link href="mailto:janek9527@gmail.com">
                  <Chip icon={<Mail />} label="- dev. 2020 Janek Gass -" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className={classes.action}>
          <Button
            fullWidth
            onClick={() => setOpen(false)}
            startIcon={<Close />}
          >
            Schließen
          </Button>
        </div>
      </Drawer>

      <Fab className={classes.fab} onClick={() => setOpen(true)}>
        <SettingsIcon />
      </Fab>
    </>
  );
};

export default Settings;