import React from "react";
import {
  useMediaQuery,
  withStyles,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  Box,
  Collapse} from "@material-ui/core";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  TrendingUp,
  TrendingDown,
} from "@material-ui/icons";
import { ColoredChip } from "./Components/ColoredChip";
import { red, green } from "@material-ui/core/colors";
import { useStorageContext } from "./Components/Provider/StorageProvider";

const CustomTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

const RankTableCell = withStyles((theme) => ({
  root: {
    textAlign: "center",
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export const SoloStandings = (props) => {
  const standingProps = { ...props };
  const lowRes = useMediaQuery("(max-width: 768px)");

  const players = standingProps.players;
  return (
      <>
  <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size={lowRes ? "small" : "medium"}>
              <TableHead>
                <TableRow>
                  <CustomTableCell />
                  <RankTableCell>Pl</RankTableCell>
                  <CustomTableCell>Spieler</CustomTableCell>
                  <CustomTableCell>Rating</CustomTableCell>
                  <CustomTableCell>Sp</CustomTableCell>
                  <CustomTableCell>S</CustomTableCell>
                  <CustomTableCell>N</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.map((p, idx) => (
                  <PlayerRow player={p} key={p.rowKey} rank={idx + 1} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={showGraph}
                onChange={() => setShowGraph(!showGraph)}
                name="showGraph"
                color="primary"
              />
            }
            label="Verlaufsgraph anzeigen"
          />
          <Fade in={showGraph} mountOnEnter unmountOnExit>
            <Graph matches={matches} players={players} />
          </Fade>
        </Grid> */}
        </>
  );
};

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  expandButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const PlayerRow = (props) => {
  const { player, rank } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={classes.root}
        hover
        onClick={(event) => setOpen(!open)}
      >
        <CustomTableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CustomTableCell>
        <RankTableCell>{rank}</RankTableCell>
        <CustomTableCell>
          <Typography noWrap variant="body2">
            {player.name}
          </Typography>
        </CustomTableCell>
        <CustomTableCell>{player.rating.toFixed(0)}</CustomTableCell>
        <CustomTableCell>{player.matchesPlayed}</CustomTableCell>
        <CustomTableCell>{player.matchesWon}</CustomTableCell>
        <CustomTableCell>{player.matchesLost}</CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell padding="none" colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit mountOnEnter>
            <PlayerMatches player={player} />
          </Collapse>
        </CustomTableCell>
      </TableRow>
    </>
  );
};

const PlayerMatches = (props) => {
  const { player } = props;
  const { storage } = useStorageContext();

  //only get matches for the selected player
  const getMatchesForPlayer = (player) => {
    return storage.matches.filter(
      (m) =>        
        m.playerA1Id === player.rowKey ||
        m.playerA2Id === player.rowKey ||
        m.playerB1Id === player.rowKey ||
        m.playerB2Id === player.rowKey
    );
  };

  return (
    <Box margin={1}>
      <Table size="small">
        <TableBody>
          {getMatchesForPlayer(player).map((m) => (
            <MatchRow player={player} match={m} key={m.rowKey} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const MatchRow = (props) => {
  const { player, match } = props;
  const { storage } = useStorageContext();
  const players = storage.players;
  

  const getPlayerNameById = (id) => {
    return players.find((p) => p.rowKey === id).name;
  }

  const getRatingForPlayerId = () => {
    if (match.playerA1Id === player.rowKey){
      return match.playerA1RatingDelta;
    }
    if (match.playerA2Id === player.rowKey){
      return match.playerA2RatingDelta;
    }
    if (match.playerB1Id === player.rowKey){
      return match.playerB1RatingDelta;
    }
    if (match.playerB2Id === player.rowKey){
      return match.playerB2RatingDelta;
    }
  }

  const teamA = [
    { name: getPlayerNameById(match.playerA1Id), rowKey: match.playerA1Id },
    { name: getPlayerNameById(match.playerA2Id), rowKey: match.playerA2Id },
  ];
  const teamB = [
    { name: getPlayerNameById(match.playerB1Id), rowKey: match.playerB1Id },
    { name: getPlayerNameById(match.playerB2Id), rowKey: match.playerB2Id },
  ];
  const wasTeamA = teamA.some((p) => p.rowKey === player.rowKey);
  const winner = (wasTeamA && match.teamAWins) || (!wasTeamA && !match.teamAWins);
  const ownTeam = wasTeamA ? teamA : teamB;
  const opponentTeam = wasTeamA ? teamB : teamA;
  const teamMate = ownTeam.find((p) => p.rowKey !== player.rowKey);
  
  return (
    <TableRow key={match.rowKey}>
      <CustomTableCell>
        <ColoredChip
          icon={winner ? <TrendingUp /> : <TrendingDown />}
          label={getRatingForPlayerId().toFixed(0)}
          backgroundColor={winner ? green[500] : red[500]}
          size="small"
        />
      </CustomTableCell>
      <CustomTableCell>
        <div>
          <div>{player.name}</div>
          <div>{teamMate.name}</div>
        </div>
      </CustomTableCell>
      <CustomTableCell>vs.</CustomTableCell>
      <CustomTableCell>
        <div>
          <div>{opponentTeam[0].name}</div>
          <div>{opponentTeam[1].name}</div>
        </div>
      </CustomTableCell>
    </TableRow>
  );
}