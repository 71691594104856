import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import React from "react";

const Context = createContext(null);

export const useAuthContext = () => useContext(Context);

const AuthProvider = ({ children }) => {
  const [authToken, setAuthTokenState] = useState();

  const setAuthToken = useCallback(
    (token) => {
      localStorage.setItem("authToken", JSON.stringify(token));
      setAuthTokenState(token);
    },
    [setAuthTokenState]
  );

  const getInitialToken = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("authToken"));
    setAuthToken(token);
  }, [setAuthToken]);

  useEffect(() => {
    getInitialToken();
  }, [getInitialToken]);

  return (
    <Context.Provider value={{ authToken, setAuthToken }}>
      {children}
    </Context.Provider>
  );
};

export default AuthProvider;
