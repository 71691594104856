import React from "react";
import TrophyIcon from "./TrophyIcon";
import { TrendingUp, TrendingDown } from "@material-ui/icons";
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  FormControl,
  Select,
  Box,
} from "@material-ui/core";
import { useStorageContext } from "./Components/Provider/StorageProvider";
import clsx from "clsx";
import ColoredChip from "./Components/ColoredChip";
import { green, red, amber } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
  },
  trophy: {
    margin: "auto",
    fontSize: "6rem",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  hidden: {
    visibility: "hidden",
  },
  chip: {
    border: `2.5px solid ${theme.palette.background.paper}`,
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "5px"
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
}));
const TeamSelection = (props) => {
  const { team, menuItems, winner, ratingDelta } = props;
  const { storage, storageDispatch } = useStorageContext();

  const player1Key = team === "A" ? "playerA1Id" : "playerB1Id";
  const player2Key = team === "A" ? "playerA2Id" : "playerB2Id";
  const selectedMatch = storage.selectedMatch;

  const classes = useStyles();
  const trophyClassName = clsx({
    [classes.trophy]: true,
    [classes.hidden]: !winner,
  });

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      value={selectedMatch[player1Key]}
                      onChange={(ev) => {
                        storageDispatch({
                          type: "selectedMatchChange",
                          selectedMatch: {
                            ...selectedMatch,
                            [player1Key]: ev.target.value,
                          },
                        });
                      }}
                    >
                      {menuItems}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      fullWidth
                      value={selectedMatch[player2Key]}
                      onChange={(ev) => {
                        storageDispatch({
                          type: "selectedMatchChange",
                          selectedMatch: {
                            ...selectedMatch,
                            [player2Key]: ev.target.value,
                          },
                        });
                      }}
                    >
                      {menuItems}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.flexCenter}>
              <TrophyIcon className={trophyClassName} htmlColor={amber[500]}/>
              {ratingDelta?.length > 1 && (
                <Box justifyContent="center" className={classes.box}>
                  <Grid direction="row" spacing={5} >
                  <ColoredChip
                    className={classes.chip}
                    icon={winner ? <TrendingUp /> : <TrendingDown />}
                    label={ratingDelta[0]?.toFixed(0)}
                    backgroundColor={winner ? green[500] : red[500]}
                    size="medium"
                  />
                  <Grid direction="row" spacing={5}>
                  <ColoredChip
                    className={classes.chip}
                    icon={winner ? <TrendingUp /> : <TrendingDown />}
                    label={ratingDelta[1]?.toFixed(0)}
                    backgroundColor={winner ? green[500] : red[500]}
                    size="medium"
                  />
                  </Grid>
                  </Grid>
                </Box>
             )} 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default TeamSelection;