import React from "react";
import {
  Backdrop,
  CircularProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    flexDirection: "column",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
export const Loading = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" className={classes.margin} />
      <Typography className={classes.margin}>
        Daten werden geladen...
      </Typography>
    </Backdrop>
  );
};
