import React from "react";
import { makeStyles, Chip, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
    },
    label: {
      color: ({ backgroundColor }) =>
        backgroundColor
          ? theme.palette.getContrastText(backgroundColor)
          : undefined,
    },
    icon: {
      color: ({ backgroundColor }) =>
        backgroundColor
          ? theme.palette.getContrastText(backgroundColor)
          : undefined,
    },
  })
);
export const ColoredChip = (props) => {
  const { backgroundColor } = props;
  const chipProps = { ...props };
  const classes = useStyles({ backgroundColor });
  delete chipProps.backgroundColor;
  return <Chip {...chipProps} classes={classes} />;
};

export default ColoredChip;
